.emailDetail {
    display: flex;
    flex-direction: column;
    padding: 24px 32px;
    flex: 1;
    overflow-y: auto;
    header {
        display: flex;
        align-items: center;
        width: 100%;
        margin-bottom: 36px;
        justify-content: space-between;
        svg {
            cursor: pointer;
        }
        section {
            display: flex;
            align-items: center;
            gap: 16px;
        }
    }
    &__container {
        display: flex;
        flex-direction: column;
        .subject {
            font-weight: 600;
            font-size: 18px;
            line-height: 24px;
            color: var(--black);
            margin-bottom: 27px;
        }
        .sender {
            display: flex;
            gap: 8px;
            align-items: center;
            section {
                display: flex;
                flex-direction: column;
                gap: 4px;
                div {
                    font-weight: 700;
                    font-size: 16px;
                    line-height: 24px;
                    display: flex;
                    align-items: center;
                    gap: 8px;
                    color: var(--black);
                    span {
                        font-weight: 600;
                        font-size: 12px;
                        line-height: 16px;
                        color: #707070;
                    }
                }
                p {
                    font-weight: 600;
                    font-size: 12px;
                    line-height: 16px;
                    color: #707070;
                }
            }
        }
        div.avatar {
            min-width: 36px;
            min-height: 36px;
            max-width: 36px;
            max-height: 36px;
            border-radius: 50%;
            background-color: #333;
            display: flex;
            align-items: center;
            justify-content: center;
            color: white;
            text-align: center;
            font-size: 16px;
            font-weight: 400;
            text-transform: uppercase;
        }
        .email {
            margin-top: 28px;
            font-weight: 400;
            font-size: 14px;
            line-height: 19px;

            color: var(--black);
        }
    }
    &__button {
        margin-top: auto;
        display: flex;
        gap: 15.5px;
        button {
            background-color: none;
            border: none;
            outline: none;
            background: #ffffff;
            border: 1px solid #cacbcd;
            border-radius: 12px;
            width: 163.5px;
            height: 56px;
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 6px;
            font-weight: 600;
            font-size: 16px;
            line-height: 22px;
            color: var(--black);
            cursor: pointer;
            &:hover {
                background-color: #f2f2f2;
            }
        }
    }
}
