.sidebar {
    display: flex;
    &__col-1 {
        display: flex;
        flex-direction: column;
        width: 60px;
        align-items: center;
        padding: 22px 0;
        div {
            position: relative;
            img {
                width: 37px;
                height: 37px;
                border-radius: 50%;
                cursor: pointer;

                margin-bottom: 32px;
                &.profile {
                    background: #ffffff;
                    border: 4px solid var(--teal);
                    padding: 2px;
                    width: 43px;
                    height: 43px;
                }
            }
            span {
                border-radius: 50%;
                background: var(--teal);
                border: 1.5px solid #ffffff;
                width: 10px;
                height: 10px;
                position: absolute;
                top: 1px;
                right: 1px;
            }
        }
        section {
            height: 1px;
            width: 20px;
            background-color: #edeff1;
            margin-bottom: 38px;
        }
        svg {
            cursor: pointer;
        }
    }
    &__col-2 {
        display: flex;
        height: 100%;
        flex-direction: column;
        border-left: 1px solid #edeff1;
        width: 241px;
        padding-right: 9px;
        border-right: 1px solid #edeff1;
    }
    &__input-type {
        display: flex;
        align-items: center;
        margin-top: 20px;
        font-weight: 700;
        font-size: 16px;
        line-height: 22px;
        color: var(--black);
        padding-left: 24px;
        height: 40px;
        cursor: pointer;
        margin-bottom: 16px;
        position: relative;
        svg {
            &:first-of-type {
                margin-right: 19.5px;
            }
            &:last-of-type {
                margin-left: 31px;
                display: none;
            }
        }
    }
    &__options {
        display: flex;
        flex-direction: column;
        gap: 16px;
        padding-bottom: 23px;
        border-bottom: 1px solid #edeff1;
        div {
            font-weight: 400;
            font-size: 14px;
            line-height: 19px;
            padding: 9px 0;
            color: var(--black);
            display: flex;
            align-items: center;
            padding-right: 16px;
            cursor: pointer;
            padding-left: 24px;

            svg {
                margin-right: 16px;
                path {
                    stroke: #707070;
                }
            }

            &.bold {
                font-weight: 700;
            }
            &.active {
                color: #4b8298;
                font-weight: 700;
                background: #edf7f8;
                border-radius: 0px 12px 12px 0px;
                span {
                    font-weight: 700;
                    color: #4b8298;
                }
                svg {
                    path {
                        stroke: #4b8298;
                    }
                }
            }
            span {
                font-weight: 400;
                font-size: 12px;
                line-height: 16px;
                margin-left: auto;
                color: #767676;
            }
        }
    }
    &__away {
        display: flex;
        flex-direction: column;
        gap: 13px;
        padding: 18px 8px 26px 0px;
        main {
            display: flex;
            flex-direction: column;
            gap: 8px;
            div {
                display: flex;
                align-items: center;
                cursor: pointer;
                font-weight: 400;
                font-size: 14px;
                line-height: 19px;
                gap: 16px;
                padding: 8.5px 0;
                padding-left: 18px;
                svg {
                    width: 24px;
                }
                &.active {
                    background: #edf7f8;
                    border-radius: 0px 12px 12px 0px;
                    svg {
                        path {
                            stroke: #4b8298;
                        }
                    }
                }
            }
        }
        border-bottom: 1px solid #edeff1;
    }
    &__title {
        font-weight: 700;
        font-size: 14px;
        line-height: 19px;
        color: var(--black);
        margin-left: 18px;
    }
    &__contacts {
        padding: 8px 8px 8px 0px;
        display: flex;
        flex-direction: column;
        gap: 13px;
        main {
            display: flex;
            flex-direction: column;
            gap: 8px;
            div {
                display: flex;
                align-items: center;
                cursor: pointer;
                font-weight: 400;
                font-size: 14px;
                line-height: 19px;
                gap: 16px;
                padding: 8.5px 0;
                padding-left: 18px;
                svg {
                    width: 24px;
                }
            }
        }
    }
}
