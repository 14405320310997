.feed {
    display: flex;
    min-height: 100vh;
    max-height: 100vh;
    flex-direction: column;
    overflow-y: hidden;
    position: relative;

    &__container {
        flex: 1;
        display: flex;
        overflow-y: auto;
    }
    &__box {
        display: flex;
        flex-direction: column;
        flex: 1;
        &-menu {
            display: flex;
            align-items: center;
            padding: 11px 24px 14.5px 17px;
            justify-content: space-between;
            border-bottom: 1px solid #edeff1;
            &.away {
                background: linear-gradient(136.05deg, #4b8298 25.44%, #3c6376 96.17%);
                section {
                    color: white;
                }
            }
            div.checkbox {
                display: flex;
                gap: 7.17px;
                input {
                    border: #707070;
                    width: 16.5px;
                    height: 16.5px;
                    cursor: pointer;
                }
                svg {
                    fill: #707070;
                    width: 7.5px;
                    margin-top: 4px;
                }
            }
            div.away {
                font-weight: 600;
                font-size: 16px;
                line-height: 24px;
                color: white;
                display: flex;
                align-items: center;
                gap: 16px;
                main {
                    display: flex;
                    align-items: center;
                    gap: 9px;
                    font-weight: 400;
                    font-size: 13px;
                    line-height: 18px;
                    background-color: white;
                    padding: 6px 18px 6px 11px;
                    border-radius: 100px;
                    color: var(--black);
                    span {
                        width: 8px;
                        height: 8px;
                        border-radius: 50%;
                        background-color: #b14614;
                    }
                }
            }
            section {
                display: flex;
                align-items: center;
                font-weight: 400;
                font-size: 14px;
                line-height: 19px;
                color: var(--black);
            }
        }
    }
    &__list {
        &-container {
            &-box {
                display: flex;
                overflow-y: auto;
            }
            &-overlay {
                display: flex;
                flex: 1;
                position: relative;
                overflow-y: auto;
                svg {
                    position: absolute;
                    width: 61px;
                    top: -50%;
                    right: -10%;
                }
            }
            display: flex;
            flex-direction: column;
            width: 100%;
            padding-bottom: 32px;
            overflow-y: auto;

            main {
                display: flex;
                flex-direction: column;
            }
            &.away {
                filter: blur(20px);
                background: rgba(255, 255, 255, 0.22);
                border: 1px solid rgba(255, 255, 255, 0.2);
            }
        }
        &-day {
            display: flex;
            align-items: center;
            gap: 7px;
            margin-block: 19px;
            font-weight: 700;
            font-size: 16px;
            line-height: 16px;
            color: var(--black);
            margin-left: 20px;
        }
    }
    &__contacted {
        &-container {
            display: flex;
            overflow-y: auto;
            min-width: 300px;
            max-width: 310px;
            flex-direction: column;
            padding: 28px 18px 28px 0;
            border-left: 1px solid #edeff1;
            section {
                display: flex;
                flex-direction: column;
                gap: 24px;
                margin-bottom: 24px;
                div {
                    img {
                        width: 24px;
                        height: 24px;
                        border-radius: 50%;
                        margin-right: 8px;
                    }
                    display: flex;
                    align-items: center;
                    padding-left: 24px;
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 19px;
                    color: var(--black);
                    svg {
                        margin-left: auto;
                        cursor: pointer;
                    }
                }
                main {
                    display: flex;
                    align-items: center;
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 19px;
                    color: var(--black);
                    padding: 2px 4px 2px 24px;
                    border-radius: 12px;
                    overflow: hidden;
                    white-space: nowrap;
                    &:hover {
                        background: #edf7f8;
                        border-radius: 12px;
                    }
                    svg {
                        margin-right: 8px;
                        min-width: 24px;
                        min-height: 24px;
                    }
                    .MuiSwitch-root {
                        margin-left: auto !important;
                        .Mui-checked {
                            .MuiSwitch-thumb {
                                background-color: #4b8298 !important;
                            }
                            ~ .MuiSwitch-track {
                                background-color: #4b8298 !important;
                            }
                        }
                    }
                }
            }
        }
        &-title {
            font-weight: 700;
            font-size: 14px;
            line-height: 19px;
            color: var(--black);
            margin-bottom: 17px;
            margin-left: 24px;
        }
    }
}

