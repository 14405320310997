.createAway {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    min-height: 100vh;
    background-color: white;
    z-index: 8000;
    display: flex;
    flex-direction: column;
    &__close {
        position: absolute;
        top: 40px;
        left: 40px;
        cursor: pointer;
    }
    main {
        width: 350px;
        margin: 0 auto;
        margin-top: 40px;
        flex: 1;
        display: flex;
        align-items: center;
        flex-direction: column;
        min-width: 350px;

        h2 {
            font-weight: 700;
            font-size: 28px;
            line-height: 38px;
            color: var(--black);
        }
        p {
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            text-align: center;
            color: var(--black);
            margin-top: 16px;
        }
        section {
            margin-top: 24px;
            display: flex;
            flex-direction: column;
            gap: 16px;
            width: 100%;
            .box {
                background: #ffffff;
                border: 1px solid var(--gray);
                box-shadow: 0px 6px 14px rgba(0, 0, 0, 0.05), 0px 0px 0px rgba(0, 0, 0, 0.05);
                border-radius: 12px;
                padding: 18px 16px;
                font-weight: 400;
                font-size: 16px;
                line-height: 24px;
                color: #707070;
                display: flex;
                align-items: center;
                justify-content: space-between;
                width: 100%;
                cursor: pointer;
                span {
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 24px;
                    color: var(--black);
                }
                &__title {
                    font-weight: 700;
                    padding-inline: 16px;
                    font-size: 26px;
                    line-height: 32px;
                    color: var(--main);
                    margin-bottom: 16px;
                }
                &.duration {
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    padding: 18px 0;

                    ul {
                        display: flex;
                        flex-direction: column;
                        width: 100%;
                        li {
                            display: flex;
                            align-items: center;
                            gap: 16px;
                            cursor: pointer;
                            padding: 8px 16px;
                            width: 100%;
                            transition: all 0.2s ease;
                            &:hover {
                                background-color: #edf7f8;
                            }
                        }
                    }
                }
                &.title {
                    display: flex;
                    flex-direction: column;
                    padding: 18px 0;
                    align-items: flex-start;
                    input {
                        background-color: none;
                        border: none;
                        outline: none;
                        margin: 0 16px;
                        border: 1px solid var(--border);
                        border-radius: 12px;
                        height: 55px;
                        padding: 0 12px;
                        font-weight: 400;
                        font-size: 16px;
                        line-height: 24px;
                        color: var(--black);
                        width: calc(100% - 32px);
                    }
                }
                &.message {
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    padding: 18px 0;
                    textarea {
                        background-color: none;
                        border: none;
                        outline: none;
                        margin: 0 16px;
                        border: 1px solid var(--border);
                        border-radius: 12px;
                        padding: 12px;
                        font-weight: 400;
                        height: 145px;
                        font-size: 16px;
                        line-height: 24px;
                        color: var(--black);
                        width: calc(100% - 32px);
                        resize: none;
                    }
                }
                &.contact {
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    padding: 18px 0;
                    .contact__box {
                        display: flex;
                        align-items: center;
                        padding-inline: 16px;
                        gap: 16px;
                        font-weight: 400;
                        font-size: 16px;
                        line-height: 24px;
                        color: var(--black);
                    }
                }
                &.keywords {
                    display: flex;
                    flex-direction: column;
                    padding: 18px 0;
                    align-items: flex-start;
                    textarea {
                        background-color: none;
                        border: none;
                        outline: none;
                        margin: 0 16px;
                        border: 1px solid var(--border);
                        border-radius: 12px;
                        padding: 12px;
                        font-weight: 400;
                        height: 145px;
                        font-size: 16px;
                        line-height: 24px;
                        color: var(--black);
                        width: calc(100% - 32px);
                        resize: none;
                    }
                    p {
                        font-weight: 400;
                        font-size: 12px;
                        line-height: 16px;
                        color: #707070;
                        padding: 0 16px;
                        margin: 0;
                        margin-top: 5px;
                    }
                }
            }
        }
    }
    footer {
        min-height: 80px;
        max-height: 80px;
        display: flex;
        padding: 12px 40px;
        border-top: 1px solid #edeff1;

        .progress {
            display: flex;
            align-items: center;
            gap: 10px;
            margin-left: auto;
            margin-right: -7%;
            div {
                background: #e8e8e8;
                border-radius: 100px;
                min-width: 10px;
                min-height: 10px;
                &.active {
                    background: #a4d0f0;
                    border-radius: 100px;
                    min-width: 32px;
                }
            }
        }

        button {
            background-color: none;
            border: none;
            outline: none;
            background: #4b8298;
            border-radius: 12px;
            height: 56px;
            width: 132px;
            font-weight: 600;
            font-size: 16px;
            line-height: 22px;
            text-align: center;
            color: #ffffff;
            margin-left: auto;
            cursor: pointer;
            &:disabled {
                opacity: 0.5;
            }
        }
    }
}
