.inbox {
    &__box {
        display: flex;
        flex-direction: column;
        flex: 1;
        &-menu {
            display: flex;
            align-items: center;
            padding: 11px 26px 14.5px 17px;
            justify-content: space-between;
            border-bottom: 1px solid #edeff1;
            &.away {
                background: linear-gradient(136.05deg, #4b8298 25.44%, #3c6376 96.17%);
                section div {
                    color: white;
                }
            }
            section {
                main {
                    display: flex;
                    align-items: center;
                    gap: 16px;
                    margin-right: 15px;
                    span {
                        white-space: nowrap;
                    }
                    svg {
                        min-width: 16px;
                        cursor: pointer;
                        min-height: 16px;
                        &:first-of-type {
                            transform: rotate(180deg);
                            &:hover {
                                transform: rotate(180deg) scale(1.07);
                            }
                        }
                        transition: all 0.2s;
                        &:hover {
                            transform:  scale(1.07);
                        }
                        &.false{
                            opacity: 0.4;
                            cursor: auto;
                        }
                    }
                }
            }

            div.away {
                font-weight: 600;
                font-size: 16px;
                line-height: 24px;
                color: white;
                display: flex;
                align-items: center;
                gap: 16px;
                main {
                    display: flex;
                    align-items: center;
                    gap: 9px;
                    font-weight: 400;
                    font-size: 13px;
                    line-height: 18px;
                    background-color: white;
                    padding: 6px 18px 6px 11px;
                    border-radius: 100px;
                    color: var(--black);
                    span {
                        width: 8px;
                        height: 8px;
                        border-radius: 50%;
                        background-color: #b14614;
                    }
                }
            }
            div.checkbox {
                display: flex;
                gap: 7.17px;
                input {
                    border: #707070;
                    width: 16.5px;
                    height: 16.5px;
                    cursor: pointer;
                }
                svg {
                    fill: #707070;
                    width: 7.5px;
                    margin-top: 4px;
                }
            }
            section {
                display: flex;
                align-items: center;
                font-weight: 400;
                font-size: 14px;
                line-height: 19px;
                color: var(--black);
            }
        }
    }
    &__list {
        &-container {
            display: flex;
            flex-direction: column;
            overflow-y: auto;
            padding-bottom: 32px;
            position: relative;
            main {
                display: flex;
                flex-direction: column;
            }
        }
        &-day {
            display: flex;
            align-items: center;
            gap: 7px;
            margin-block: 19px;
            font-weight: 700;
            font-size: 16px;
            line-height: 16px;
            color: var(--black);
            margin-left: 20px;
        }
    }
}

.write__email {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 48px;
    height: 48px;
    background-color: #4b8298;
    border-radius: 50%;
    position: absolute;
    bottom: 32px;
    right: 18px;
    z-index: 1000;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    &:hover {
        transform: scale(1.1);
    }
}
