.feedbox {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding-right: 16px;
    padding-top: 12px;
    padding-bottom: 12px;
    cursor: pointer;
    &:hover {
        background-color: #edf7f8;
    }
    section {
        display: flex;
        flex-direction: column;
        gap: 8px;
    }
    &__row-1 {
        display: flex;
        align-items: center;
        input {
            width: 16.5px;
            height: 16.5px;
            border: 2px solid #00000029;
            outline: none;
            margin-left: 17px;
            margin-right: 10.5px;
        }
        span {
            width: 8px;
            height: 8px;
            background-color: #4b8298;
            margin-right: 8px;
            border-radius: 50%;
        }
        div.avatar {
            margin-left: 8px;
            margin-right: 3px;
            min-width: 36px;
            min-height: 36px;
            max-width: 36px;
            max-height: 36px;
            border-radius: 50%;
            background-color: #333;
            display: flex;
            align-items: center;
            justify-content: center;
            color: white;
            text-align: center;
            font-size: 15px;
            font-weight: 400;
            text-transform: uppercase;
        }
        div {
            font-weight: 700;
            font-size: 17px;
            line-height: 23px;
            color: var(--primary);
            margin-left: 8px;
        }
    }
    &__row-2 {
        margin-left: 60px;
        display: flex;
        flex-direction: column;
        gap: 11px;
        div.title {
            font-weight: 700;
            font-size: 26px;
            line-height: 32px;
            color: var(--black);
        }
        p {
            color: var(--black);
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            max-width: 85%;
        }
    }
    img {
        border-radius: 12px;
        width: 132px;
        height: 132px;
    }
}
