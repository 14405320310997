.profile {
    display: flex;

    width: 100%;
    flex: 1;
    &__container {
        flex: 1;
        display: flex;
        padding-top: 48px;
        width: 100%;
        section {
            display: flex;
            flex-direction: column;
            width: 380px;
            margin: 0 auto;
            h2 {
                font-weight: 700;
                font-size: 34px;
                line-height: 40px;
                text-align: center;
                letter-spacing: -0.02em;
                color: var(--main);
            }
        }
        &-box {
            display: flex;
            align-items: center;
            gap: 16px;
            padding-bottom: 16px;
            border-bottom: 1px solid #edeff1;
            margin-top: 30px;
            margin-bottom: 37px;
            img {
                width: 54px;
                height: 54px;
                border-radius: 50%;
            }
            div {
                display: flex;
                flex-direction: column;
                gap: 4px;
                p,
                span {
                    font-size: 16px;
                    line-height: 24px;
                    color: var(--black);
                }
                span {
                    font-weight: 700;
                }
                p {
                    font-weight: 400;
                }
            }
        }
    }
    &__upgrade {
        display: flex;
        flex-direction: column;
        padding: 45px 27px 66px 45px;
        align-items: center;
        background: #ffffff;
        border: 1px solid var(--gray);
        border-radius: 12px;
        &-title {
            font-weight: 700;
            font-size: 24px;
            line-height: 32px;
            color: #172b4d;
            margin-top: 37px;
            margin-bottom: 16px;
        }
        p {
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            color: var(--black);
            margin-bottom: 33px;
        }
        button {
            outline: none;
            border: none;
            background-color: none;
            background: #4b8298;
            border-radius: 12px;
            display: flex;
            align-items: center;
            justify-content: center;
            font-weight: 600;
            font-size: 16px;
            line-height: 22px;
            color: #ffffff;
            padding: 17px 0;
            width: 100%;
            cursor: pointer;
        }
    }
}
