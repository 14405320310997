@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700;800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Work+Sans:wght@100;200;300;400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;500&display=swap");

*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Open Sans", sans-serif;
}

:root {
  --away: #f5f4f4;
  --black: #333333;
  --border: #a3a3a3;
  --gray: #f2f2f2;
  --teal: #49b2b9;
  --primary: #202124;
  --main: #15141f;
}

body {
  font-size: 1.6rem;
  background: white;
  font-weight: 400;
}

.main {
  display: flex;
  min-height: 100vh;
  max-height: 100vh;
  flex-direction: column;
  overflow-y: hidden;
  position: relative;
}

.container {
  flex: 1;
  display: flex;
  overflow-y: auto;
}

#goo-loader {
  margin: 0 auto;
  margin-top: 54px;
}

.ant-picker-dropdown {
  z-index: 9999 !important;
}

.ant-picker {
  margin-left: -8px !important;
  .ant-picker-input input {
    font-family: "Open Sans" !important;
    font-size: 16px !important;
    font-weight: 400 !important;
    line-height: 24px !important;
    &::placeholder {
      font-family: "Open Sans" !important;
      font-size: 16px !important;
      font-weight: 400 !important;
      line-height: 24px !important;
    }
  }
}


.away__box {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: fixed;
  top: 25%;
  left: 48%;

  z-index: 1000;
  gap: 24px;
  h2 {
      font-family: "Work Sans", sans-serif;
      font-weight: 700;
      font-size: 34px;
      line-height: 40px;
      letter-spacing: -0.02em;
      color: #15141f;
  }
  p {
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      text-align: center;
      color: var(--black);
      width: 338px;
  }
  div {
      background: #4b8298;
      border-radius: 12px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: 600;
      font-size: 16px;
      line-height: 22px;
      color: #ffffff;
      cursor: pointer;
      height: 56px;
      width: 340px;
      margin-top: 16px;
  }
}
