.searchbar {
    background: var(--gray);
    border: 1px solid var(--gray);
    border-radius: 8px;
    width: 720px;
    height: 48px;
    padding: 15px 20px;
    display: flex;
    align-items: center;
    gap: 11.5px;
    margin-block: 8px;
    position: relative;

    input {
        background: none;
        outline: none;
        border: none;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: var(--black);
        flex: 1;
        width: 100%;
    }
    &.active {
        z-index: 9999;
        background: #ffffff;
        border: 1px solid var(--gray);
        box-shadow: 0px 317px 127px rgba(0, 0, 0, 0.01), 0px 178px 107px rgba(0, 0, 0, 0.05),
            0px 79px 79px rgba(0, 0, 0, 0.09), 0px 20px 44px rgba(0, 0, 0, 0.1), 0px 0px 0px rgba(0, 0, 0, 0.1);
        border-radius: 8px 8px 0 0;
        .history {
            display: flex;
            // align-items: center;
            // justify-content: center;
            position: absolute;
            flex-direction: column;
            gap: 16px;
            top: 42px;
            left: -1px;
            width: 720px;
            height: 350px;
            background: #ffffff;
            border: 1px solid var(--gray);
            box-shadow: 0px 317px 127px rgba(0, 0, 0, 0.01), 0px 178px 107px rgba(0, 0, 0, 0.05),
                0px 79px 79px rgba(0, 0, 0, 0.09), 0px 20px 44px rgba(0, 0, 0, 0.1), 0px 0px 0px rgba(0, 0, 0, 0.1);
            border-radius: 0 0 8px 8px;
            padding: 16px 24px;
            overflow-y: auto;
            div {
                display: flex;
                align-items: center;
                gap: 16px;
                font-weight: 400;
                font-size: 16px;
                line-height: 24px;
                color: (--black);
                cursor: pointer;
            }
            svg{
                align-self: center;
                justify-self: center;
                height: 100%;
            }
        }
    }
}
