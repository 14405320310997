.composeEmail {
    &__container {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        min-height: 100vh;
        max-height: 100vh;
        z-index: 5000;
        background-color: rgba(51, 51, 51, 0.4);
        display: flex;
        align-items: center;
        justify-content: center;
    }
    background: #ffffff;
    box-shadow: 0px 465px 186px rgba(0, 0, 0, 0.01), 0px 261px 157px rgba(0, 0, 0, 0.05),
        0px 116px 116px rgba(0, 0, 0, 0.09), 0px 29px 64px rgba(0, 0, 0, 0.1), 0px 0px 0px rgba(0, 0, 0, 0.1);
    border-radius: 12px;
    min-width: 1056px;

    height: 80vh;
    display: flex;
    flex-direction: column;

    &__box {
        display: flex;
        flex-direction: column;
        position: relative;
        flex: 1;
        padding: 40px 32px;
    }
    .away {
        display: flex;
        align-items: center;
        font-weight: 600;
        font-size: 13px;
        line-height: 24px;
        color: #4b8298;
        background: #edf7f8;
        border-radius: 12px 12px 0px 0px;
        justify-content: space-between;
        height: 93px;
        padding: 0 36px;
        .MuiSwitch-root {
            margin-left: auto !important;
            .Mui-checked {
                .MuiSwitch-thumb {
                    background-color: #4b8298 !important;
                }
                ~ .MuiSwitch-track {
                    background-color: #4b8298 !important;
                }
            }
        }
    }

    svg.close {
        position: absolute;
        top: 36px;
        left: 29px;
        cursor: pointer;
    }
    header {
        display: flex;
        align-items: center;
        font-weight: 700;
        font-size: 28px;
        line-height: 38px;
        justify-content: center;
        color: #333333;
        margin-bottom: 40px;
        svg {
            justify-self: start;
        }
    }
    section {
        display: flex;
        flex-direction: column;
        gap: 13px;
        label {
            display: flex;
            align-items: center;
            gap: 40px;
            padding-bottom: 12px;
            border-bottom: 1px solid #edeff1;
            span {
                font-weight: 400;
                font-size: 14px;
                line-height: 24px;
                color: #707070;
                width: 1%;
            }
            input {
                outline: none;
                background-color: transparent;
                border: none;
                font-weight: 400;
                font-size: 14px;
                line-height: 24px;
                color: var(--black);
                width: 100%;
                flex: 1;
            }
        }
        textarea {
            height: 60%;
            resize: none;
            min-height: 144px;
            outline: none;
            border: none;
        }
    }
    &__attachments {
        display: flex;
        align-items: center;
        gap: 12px;
        .attachment {
            background: #f2f2f2;
            border-radius: 10px;
            min-width: 120px;
            max-width: 160px;
            min-height: 40px;
            display: flex;
            padding: 9px 10px;
            display: flex;
            flex-direction: column;
            gap: 3px;
            h2 {
                font-family: "Roboto";
                font-weight: 500;
                font-size: 10px;
                line-height: 12px;
                color: #333333;
            }
            div {
                font-family: "Roboto";
                font-weight: 400;
                font-size: 8px;
                line-height: 9px;

                color: #333333;
            }
        }
    }
    footer {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: auto;
        svg {
            cursor: pointer;
        }
        div {
            display: flex;
            align-items: center;
            gap: 28px;
        }
    }
}
