.navbar {
    display: flex;
    align-items: center;
    padding: 0px 19px;
    background: #ffffff;
    box-shadow: inset 0px -1px 0px #edeff1;
    &__hamburger {
        display: flex;
        flex-direction: column;
        gap: 3px;

        div {
            width: 18px;
            height: 2.5px;
            background-color: var(--black);
        }
        ~ svg {
            margin-left: 23px;
            margin-right: 7%;
        }
    }
    &__settings {
        display: flex;
        align-items: center;
        margin-left: auto;
        section {
            height: 64px;
            width: 1px;
            background-color: #edeff1;
            margin: 0 16.5px;
        }
        &-col {
            &-1 {
                display: flex;
                align-items: center;
                justify-content: center;
                gap: 19px;
                position: relative;
                svg {
                    cursor: pointer;
                }
                .menu {
                    width: 191px;
                    background: #ffffff;
                    border: 1px solid var(--gray);
                    box-shadow: 0px 6px 14px rgba(0, 0, 0, 0.05), 0px 0px 0px rgba(0, 0, 0, 0.05);
                    border-radius: 12px;
                    display: flex;
                    flex-direction: column;
                    position: absolute;
                    top: 185%;
                    left: -115%;
                    z-index: 9999;
                    div {
                        font-weight: 400;
                        font-size: 16px;
                        line-height: 24px;
                        color: #333333;
                        padding: 24px;
                        border: 1px solid #edeff1;
                        cursor: pointer;
                        &:hover {
                            background-color: #f2f2f2;
                        }
                        &:last-of-type {
                            border: none;
                        }
                    }
                }
                img {
                    border-radius: 50%;
                    width: 36px;
                    height: 36px;
                }
            }
            &-2 {
                display: flex;
                align-items: center;
                span {
                    width: 8.33px;
                    height: 8.33px;
                    background-color: #49b2b9;
                    border-radius: 50%;
                    margin-right: 9px;
                }
                font-weight: 400;
                font-size: 16px;
                line-height: 24px;
                color: var(--black);
                svg {
                    margin-left: 9px;
                }
            }
        }
    }
}
