.manageaways {
    display: flex;
    flex: 1;
    &__container {
        flex: 1;
        display: flex;
        width: 100%;
    }
    &__box {
        display: flex;
        margin: 0 auto;
        width: 380px;
        padding-top: 68px;
        flex-direction: column;

        h2 {
            font-weight: 700;
            font-size: 34px;
            line-height: 40px;
            text-align: center;
            letter-spacing: -0.02em;
            color: var(--main);
            font-family: "Work Sans", sans-serif;
        }
        ul {
            display: flex;
            flex-direction: column;
            margin-top: 40px;
            list-style: none;
            gap: 16px;
            li {
                display: flex;
                align-items: center;
                gap: 12px;
                svg {
                    min-width: 40px;
                    min-height: 40px;
                }
                span {
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 24px;
                    color: var(--black);
                }
            }
        }
        button {
            background-color: none;
            margin-top: 24px;
            border: none;
            outline: none;
            background: #4b8298;
            border-radius: 12px;
            height: 56px;
            width: 343px;
            font-weight: 600;
            font-size: 16px;
            line-height: 22px;
            text-align: center;
            color: #ffffff;
            align-self: center;
            cursor: pointer;
        }
    }
}
