.login {
    background: linear-gradient(136.05deg, #4b8298 25.44%, #3c6376 96.17%);
    width: 100%;
    min-height: 100vh;
    max-height: 100vh;
    display: flex;
    align-items: center;
    position: relative;
    img {
        position: absolute;
        &.swimmer {
            bottom: 107px;
            right: 95px;
        }
        &.plane {
            top: 100px;
            left: 100px;
        }
    }
    &__container {
        display: flex;
        flex-direction: column;
        margin-left: 100px;

        p {
            margin-top: 44px;
            margin-bottom: 48px;
            width: 572px;
            font-weight: 700;
            font-size: 34px;
            line-height: 46px;
            color: var(--away);
        }
        &-google {
            display: flex;
            align-items: center;
            font-weight: 600;
            font-size: 16px;
            line-height: 24px;
            color: var(--black);
            padding: 16px;
            background: #ffffff;
            border: 1px solid var(--border);
            border-radius: 16px;
            width: 343px;
            gap: 52px;
            cursor: pointer;
        }
    }
}
