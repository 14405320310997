.emaillist {
    display: flex;
    flex-direction: column;
    gap: 2px;
    width: 100%;
    padding-bottom: 14px;
    cursor: pointer;
    padding-top: 2px;
    &:hover {
        background-color: #edf7f8;
    }
    &-row-1 {
        display: flex;
        align-items: center;

        input {
            width: 16.5px;
            height: 16.5px;
            border: 2px solid #00000029;
            outline: none;
            margin-left: 17px;
            cursor: pointer;
        }
        div.avatar {
            margin-left: 10.5px;
            margin-right: 8px;
            min-width: 36px;
            min-height: 36px;
            max-width: 36px;
            max-height: 36px;
            border-radius: 50%;
            background-color: #333;
            display: flex;
            align-items: center;
            justify-content: center;
            color: white;
            text-align: center;
            font-size: 16px;
            font-weight: 400;
            text-transform: uppercase;
        }
        p {
            font-size: 17px;
            line-height: 23px;
            color: var(--primary);
            font-weight: 400;
            &.read {
                font-weight: 700;
            }
        }
        div {
            font-size: 14px;
            line-height: 19px;
            color: #707070;
            margin-left: auto;
            font-weight: 400;
            margin-right: 16px;
            &.read {
                font-weight: 700;
            }
        }
    }
    &-row-2 {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-right: 20px;

        div {
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            color: #707070;
            margin-left: 86px;
            span {
                color: var(--black);
            }
        }
        main {
            background-color: #49b2b9;
            min-width: 8px;
            min-height: 8px;
            max-width: 8px;
            max-height: 8px;
            border-radius: 50%;
        }
    }
}
